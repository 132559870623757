import { useRecordContext, useUpdate } from "react-admin";
import { Switch, FormControlLabel } from "@mui/material";

export const InlineBooleanInput = ({ source }: { source: string }) => {
    const record = useRecordContext();
    const [update] = useUpdate();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        update(
            "sell-offers",
            {
                id: record.id,
                data: { [source]: e.target.checked }
            },
            {
                mutationMode: "optimistic"
            }
        );
    };

    return (
        <FormControlLabel
            control={
                <Switch
                    checked={record[source] || false}
                    onChange={handleChange}
                    onClick={(e) => e.stopPropagation()}
                    size="small"
                />
            }
            label=""
            sx={{
                margin: 0,
                "& .MuiSwitch-root": {
                    margin: 0
                }
            }}
        />
    );
};
