import { Box, Button } from "@mui/material";
import MapIcon from "@mui/icons-material/Map";
import { useRecordContext } from "react-admin";

export const GoogleMapsButton = () => {
    const record = useRecordContext();

    if (!record || !record.latitude || !record.longitude) return null;

    const handleClick = (e: React.MouseEvent) => {
        e.stopPropagation(); // Prevent row click event from triggering
        const url = `https://www.google.com/maps?q=${record.latitude},${record.longitude}`;
        window.open(url, "_blank");
    };

    return (
        <Button
            onClick={handleClick}
            startIcon={<MapIcon />}
            size="small"
            color="primary"
        >
            Open in Maps
        </Button>
    );
};
