import { Box, Typography } from "@mui/material";
import { useRecordContext } from "react-admin";

interface ColorPreviewFieldProps {
    source: string;
    label?: string;
    sortable?: boolean;
}

export const ColorPreviewField = ({ source }: ColorPreviewFieldProps) => {
    const record = useRecordContext();
    const value = record?.[source];

    if (!value) return null;

    return (
        <Box display="flex" alignItems="center" gap={1}>
            <Typography>{value}</Typography>
            <Box
                width={24}
                height={24}
                bgcolor={value}
                borderRadius={1}
                sx={{ flexShrink: 0 }}
            />
        </Box>
    );
};
