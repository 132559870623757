import {
    AutocompleteArrayInput,
    BooleanInput,
    Create,
    DateTimeInput,
    NumberInput,
    ReferenceArrayInput,
    SelectInput,
    SimpleForm,
    required,
    useDataProvider,
    useNotify
} from "react-admin";
import Typography from "@mui/material/Typography";
import { DefaultTextInput } from "components/default-text-input";
import { unitTypes } from "constants/unit-types";
import { DynamicWidthAutocompleteReferenceInput } from "components/dynamic-width-autocomplete-input";
import { useFormContext } from "react-hook-form";
import { useEffect } from "react";

const SellOfferForm = () => {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const form = useFormContext();

    // Watch for changes in the pickUpId field
    useEffect(() => {
        const subscription = form.watch(async (value, { name }) => {
            if (name === "pickUpId" && value.pickUpId) {
                try {
                    const { data: pickUp } = await dataProvider.getOne(
                        "pick-ups",
                        { id: value.pickUpId }
                    );

                    if (pickUp?.start) {
                        const startDate = new Date(pickUp.start);

                        const deadline = new Date(
                            startDate.getTime() - 16 * 60 * 60 * 1000
                        );

                        const formattedDeadline = deadline
                            .toISOString()
                            .slice(0, 16);

                        form.setValue("deadline", formattedDeadline, {
                            shouldValidate: true,
                            shouldDirty: true,
                            shouldTouch: true
                        });

                        notify("Deadline updated successfully");
                    } else {
                        notify("Pick-up has no start date", {
                            type: "warning"
                        });
                    }
                } catch (error) {
                    notify("Error updating deadline", { type: "error" });
                }
            }
        });

        return () => subscription.unsubscribe();
    }, [dataProvider, form, notify]);

    return (
        <>
            <Typography variant="h6" sx={{ marginBottom: 1 }}>
                Create item
            </Typography>

            <DynamicWidthAutocompleteReferenceInput
                validate={[required()]}
                optionText="name"
                reference="users"
                source="userId"
            />

            <ReferenceArrayInput
                source="collaboratorIds"
                reference="users"
                alwaysOn
            >
                <AutocompleteArrayInput
                    filterToQuery={(user) => ({ name: user })}
                    optionText="name"
                    label="Collaborators"
                />
            </ReferenceArrayInput>

            <DynamicWidthAutocompleteReferenceInput
                validate={[required()]}
                optionText="title"
                reference="items"
                source="itemId"
            />

            <SelectInput
                source="unitType"
                choices={unitTypes}
                optionValue="id"
                validate={[required()]}
            />

            <DefaultTextInput
                source="unitDescription"
                multiline
                validate={[required()]}
            />

            <NumberInput source="unitPrice" validate={[required()]} />

            <DynamicWidthAutocompleteReferenceInput
                validate={[required()]}
                optionText="name"
                reference="pick-ups"
                source="pickUpId"
            />

            <NumberInput source="availability" validate={[required()]} />
            <NumberInput source="priority" />

            <DateTimeInput source="deadline" validate={[required()]} />
            <BooleanInput source="isActive" validate={[required()]} />
        </>
    );
};

export const SellOfferCreate = () => (
    <Create redirect="list">
        <SimpleForm>
            <SellOfferForm />
        </SimpleForm>
    </Create>
);
