import { BulkDeleteButtons } from "components/bulk-action-buttons/BulkDeleteButtons";
import { ListActionsToolbarWithoutFilters } from "components/toolbar/ListActionsToolbarWithoutFilters";
import {
    Datagrid,
    TextField,
    List,
    SearchInput,
    NumberField
} from "react-admin";
import { Box } from "@mui/material";
import { GoogleMapsButton } from "components/google-maps-button";

const postFilters = [
    <SearchInput
        source="name"
        alwaysOn
        placeholder="Name contains"
        resettable
    />
];

export const SpotsList = (props: any) => (
    <List
        {...props}
        sort={{ field: undefined }}
        actions={<ListActionsToolbarWithoutFilters isCreateIncluded />}
        filters={postFilters}
        perPage={50}
    >
        <Datagrid rowClick="edit" bulkActionButtons={<BulkDeleteButtons />}>
            <TextField source="name" label="Name" sortable={false} />
            <TextField
                source="description"
                label="Description"
                sortable={false}
            />
            <NumberField source="latitude" label="Latitude" sortable={false} />
            <NumberField
                source="longitude"
                label="Longitude"
                sortable={false}
            />
            <Box
                sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center"
                }}
            >
                <GoogleMapsButton />
            </Box>
        </Datagrid>
    </List>
);
